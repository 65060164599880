import React, { useEffect } from 'react';
import i18n from './i18n';
import './App.css';
import './styles.css'
import { retrieveLocalStorage } from './utils/utils';
import HomePageComp from './components/HomePageComp';
import { useTranslation } from 'react-i18next';
import HeaderComp from './components/HeaderComp';

function App() {

    const { t } = useTranslation();

    useEffect(() => {
            let localLanguage = retrieveLocalStorage("caloriator.userLanguage") || window.navigator.language;
            if(localLanguage) {
                i18n.changeLanguage(localLanguage);
            } else {
                i18n.changeLanguage('en');
            }

            if(localLanguage.startsWith('pl')) {
                console.log(localLanguage)
                document.title = "Caloriator - kalkulator kalorii"
            } else {
                document.title = "Caloriator - calories calculator"
            }
            
        }, []);

    return (
        <div className="App">
            <HeaderComp />
            <HomePageComp/>
        </div>
    );
}

export default App;
