import React from 'react'
import i18n from '../i18n';
import '../styles.css'
import { useTranslation } from 'react-i18next';
import HomePl from './home/HomePl';
import HomeEn from './home/HomeEn';

const HomePageComp = () => {

    const { t } = useTranslation();

    const currentLanguage = i18n?.language?.startsWith('pl') ? 'pl' : 'en';

    return (
        <>
            {
                currentLanguage === 'pl' &&
                <HomePl currentLanguage={currentLanguage} />
            }
            {
                currentLanguage !== 'pl' &&
                <HomeEn currentLanguage={currentLanguage} />
            }
        </>
    )
}

export default HomePageComp
