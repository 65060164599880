import { Navbar, Container, Nav, Dropdown, Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import i18n from '../i18n';
import '../styles.css';
import { saveLocalStorage } from '../utils/utils';

const HeaderComp = () => {

    let currentLanguageFlag
    if(i18n?.language?.startsWith('pl')) {
        currentLanguageFlag = 'pl.png';
    } else if(i18n?.language?.startsWith('de')) {
        currentLanguageFlag = 'de.png';
    } else {
        currentLanguageFlag = 'en.png';
    }

    const { t } = useTranslation();
    
    const handleLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
        saveLocalStorage("caloriator.userLanguage", language);
    }

    return (
        <>
            <Dropdown style={{position: 'absolute', margin: 5}}>
                <Dropdown.Toggle variant="light" style={{background: '#ffffff80', border: '1px solid #ffffff80'}}>
                    <img src={process.env.PUBLIC_URL + "/images/flags/" + currentLanguageFlag} className="" alt={currentLanguageFlag} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-menu-end'>
                    <Dropdown.Item style={{textAlign: 'center'}} onClick={() => handleLanguageChange('pl')}>
                        <img src={process.env.PUBLIC_URL + "/images/flags/pl.png"} className="" alt="pl flag" />&nbsp;&nbsp;-&nbsp;&nbsp;pl
                    </Dropdown.Item>
                    <Dropdown.Item style={{textAlign: 'center'}} onClick={() => handleLanguageChange('en')}>
                        <img src={process.env.PUBLIC_URL + "/images/flags/en.png"} className="" alt="en flag" />&nbsp;&nbsp;-&nbsp;&nbsp;en
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default HeaderComp
