import React from 'react'

interface Props {
    currentLanguage: string
}

const HomePl = ({ currentLanguage } : Props) => {
    return (
        <div className='mainContainer'>
            <img src={process.env.PUBLIC_URL + "/images/splash_1024_500_" + currentLanguage + "_no_grad.png"} className="headerImage" alt={currentLanguage} />
            
            <div className='tile'>
                <div className='tileHeader'>A więc chcesz coś zrobić ze swoją wagą</div>
                <ul>
                    <li>Chudnięcie lub przybieranie na czystej masie wydaje się prostym zadaniem. Ale, jeżeli już próbowałeś (i możliwe że się nie udało), to już wiesz że to nie takie łatwe.</li>
                    <li>Osobiście przebyłem tą drogę. Chciałem schudnąć. Próbowałem wielu rzeczy i wiele razy poniosłem porażkę. W końcu udało się. Zbiłem wagę do określonego poziomu i udaje mi się ją na nim utrzymać już długi czas.</li>
                    <li>Moim przepisem na sukces było uproszczenie wielu rzeczy i trzymanie się planu. <b>Caloriator</b> był częścią tego planu ponieważ trzymałem się jego wskazówek. Jest to narzędzie które stworzyłem dla siebie podczas mojej batalii z wagą. Może stanie się również twoim strażnikiem kalorii.</li>
                </ul>
            </div>

            <div className='tile'>
                <div className='tileHeader'>Niezbędnik. Co potrzebne aby zwiększyć swoje szanse na sukces</div>
                <ul>
                    <li><div className='liHeader'>Kontroluj co i ile jesz</div><div className='liContent'>Aby zmienić masę ciała to co jemy i ile jemy jest rzeczą najważniejszą. Na tym etapie warto posłużyć się narzędziami które pomagają w zapisywaniu ilości zjedzonych posiłków. Oczywiście można po prostu założyć że wystarczy "jeść mniej" i jest to prawda. Ale bez kontroli takie myślenie najczęściej nie sprawdza się w praktyce i nie prowadzi do dobrego wyniku. <b>Caloriator jest jednym z narzędzi które pomagają właśnie w tym punkcie.</b></div></li>
                    <li><div className='liHeader'>Motywacja i cierpliwość</div><div className='liContent'>Zrzucanie wagi nie jest szybkim procesem. Może trwać tygodniami a nawet miesiącami. Cierpliwość jest konieczna. Jeżeli w trakcie będą robione przerwy - najprawdopodobniej się nie uda</div></li>
                    <li><div className='liHeader'>Ćwiczenia</div><div className='liContent'>Ćwiczenia są niezbędne ale nie muszą być bardzo intensywne. 30-to minutowy spacer tu i tam. Domowe ćwiczenia, pompki, stepper, rowerek... jest wiele opcji. Należy zmuszać organizm do wysiłku aby spalał tłuszcz. Siedzący tryb życia sprawia że schudnięcie jest bardzo trudne ponieważ trwa bardzo długo..</div></li>
                    <li><div className='liHeader'>Bądź ze sobą szczery</div><div className='liContent'>Caloriator to tylko narządzie do wyliczania przewidywanych wyników. Wyniki te bazują na danych które zostają do niego wprowadzone. Aby wyniki były jak najbardziej zbliżone do rzeczywistości powinny być szczere - zawierać wszystko. Nie mogą pomijać "małego co nieco" albo całego dnia wypełnionego łasowaniem. Jeżeli nie jesteś ze sobą szczery to jaki ma to sens?</div></li>
                </ul>
            </div>

            <div className='tile'>
                <div className='tileHeader'>Jak używać Caloriatora</div>
                <ul>
                    <li>
                        <div className='liHeader'>Krok 1 - Oszacuj swoje dzienne zapotrzebowanie kaloryczne</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Możesz to zrobić wg własnych kryteriów lub skorzystać z wielu takich kalkulatorów online.</div>
                            <div className='liContentLine'>W przypadku Caloriatora można przejść do tego wyliczenia klikając guzik z serduszkiem na stronie głównej lub "<b>Ile potrzebuję kalorii</b>" na stronie głównej.</div>
                            <div className='liContentLine'>Otworzy się formularz do wyliczania zapotrzebowania kalorycznego. </div>
                            <div className='liContentLine'>Wybierz płeć, wagę, wzrost oraz jedną z opcji aktywności która najlepiej do ciebie pasuje.</div>
                            <div className='liContentLine'>Otrzymasz wynik opisujący ile kalorii dziennie wskazane jest w twoim przypadku do utrzymania wagi, schudnięcia lub przybrania na wadze. </div>
                            <div className='liContentLine'>Wybierz jedną z opcji. Wybrana opcja będzie miała wpływ na wyświetlanie się ostrzeń ilościowych podczas wprowadzania danych posiłków</div>
                            <div className='liContentLine'>Polecam wybrać opcję niebieską (utrzymanie wagi) i następnie próbować nie przekraczać tej wartości aby zobaczyć jak się taka czynność sprawdzi w codziennym życiu. Ustawione tutaj ograniczenia mogą być w każdej chwili zmienione w "Ustawieniach".</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesform.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesform.png"} className="" alt={currentLanguage} /></a>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesresults.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesresults.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                    <li>
                        <div className='liHeader'>Krok 2 - Zapisuj codziennie co i ile jesz</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Tą czynność wykonuje się otwierając kalkulator na konkretny dzień i dodając do niego posiłki / produkty w wyznaczonych ilościach</div>
                            <div className='liContentLine'>Caloriator zawiera swoją bazę produktów ale najprawdopodobniej każdy będzie również dodawał swoje produkty</div>
                            <div className='liContentLine'>Ponieważ często zdarza się że to co jemy danego dnia będzie również występowało innego dnia mozemy szybko skopiować zawartość innego dnia wykorzystując opcję "Kopiuj listę z innego dnia"</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/calculator_norm.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/calculator_norm.png"} className="" alt={currentLanguage} /></a>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/pickup.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/pickup.png"} className="" alt={currentLanguage} /></a>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/productlist.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/productlist.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                    <li>
                        <div className='liHeader'>Krok 3 - Pilnuj tygodniowych statystyk</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Strona główna zawsze będzie wyświetlała statystyki z bierzącego tygodnia.</div>
                            <div className='liContentLine'>Będzie wskazywać <b>Prognozę zmiany wagi</b> i <b>tygodniowy bilans caloryczny</b>. Tygodniowe ujęcie bilansów kalorycznych jest bardzo skuteczne. Jeżeli patrzymy na kalorie tylko w skali dnia często zdarza się że, gdy coś pominiemy, nie zauważymy że plan już się zmienił.</div>
                            <div className='liContentLine'>Tygodniowy bilans, jeżeli tylko bazuje na szczerych danych, bardzo dobrze pokaże czego można się spodziewać.</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/home.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/home.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                    <li>
                        <div className='liHeader'>Krok 4 - Patrz w przeszłość</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Ten punkt jest głównie dla podtrzymania motywacji. Patrz na przeszłe dane aby upewnić się że jesteś konsekwentny/na.</div>
                            <div className='liContentLine'>Z czasem, tydzien po tygodniu, log wpisów urośnie. </div>
                            <div className='liContentLine'>W moim przypadku najlepszym rozwiązaniem okazało się utrzymywani stanu prognoz na poziomie -0.5 kg tygodniowo. Był to plan który pozwalał jeszcze na komfortowe funkcjonowanie bez zbyt drastycznych rozwiązań kalorycznych.</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/logwithbar.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/logwithbar.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                </ul>
            </div>  

            <div className='tile'>
                <div className='tileHeader'>Jak mierzyć wyniki</div>
                <ul>
                    <li>
                        <div className='liHeader'>Wyniki najlepiej mierzyć raz w tygodniu a ponieważ walczymy w skali tygodniowej a niezbyt duże wartości i w związku z tym precyzja pomiaru jest bardzo ważna, dobrze jest się trzymać następujących zasad</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Korzystamy z tej samej wagi.</div>
                            <div className='liContentLine'>Pomiaru dokonujemy w tym samym miejscu.</div>
                            <div className='liContentLine'>Pomiaru dokonujemy o tej samej porze. </div>
                            <div className='liContentLine'>Dobrze jest również wziąć pod uwagę aby dzień lub dwa poprzedzające pomiar były podobne żywieniowo - celem jest wykluczenie możlwości iż np dzień poprzedzający pomiar zawierał posiłek bogaty w węglowodany bądź sól co spowoduje zatrzymaniej dużej ilości wody w  organiźmie i prawdopodobnie zaburzy wynik pomiaru. </div>
                        </div>
                    </li>
                </ul>
            </div>  
            <div className='tile'>
                <div className='tileHeader'>Jak zaimportować produkty z pliku csv ?</div>
                <ul>
                    <li>
                        <div className='liContent'>
                            <div className='liContentLine'>Możesz zaimportować Produkty do aplikacji z pliku csv.</div>
                            <div className='liContentLine'>Pobierz szablon pliku csv: <a href="https://caloriator.com/caloriator_import_products_example_pl.csv">caloriator_import_products_example_pl.csv</a>.</div>
                            <div className='liContentLine'>Wypełnij szablon produktami.</div>
                            <div className='liContentLine'>Wyślij plik na swoje urządzenie.</div>
                            <div className='liContentLine'>W aplikacji, na ekranie listy produktów, użyj ikony opcji listy produktów aby zaimportować plik.</div>
                        </div>
                    </li>
                </ul>
            </div>  
            <div className='tileHeader'>Powodzenia w Twojej kalorycznej podróży</div>
            <div className='tile' style={{textAlign: 'center'}}>
                <a href="mailto:funandgoapps@gmail.com">funandgoapps@gmail.com</a>
            </div> 
            <div className='tileHeader'>
                <div className='liImages'>   
                    <a href='https://play.google.com/store/apps/details?id=com.funandgoapps.caloriator' target="_blank"><img src={process.env.PUBLIC_URL + "/images/google-play-badge_" + currentLanguage + ".png"} className="" alt={currentLanguage} /></a>
                </div>
                <div className='liImages'>   
                    <a href='https://apps.apple.com/us/app/caloriator-calories-calculator/id6476209779' target="_blank"><img src={process.env.PUBLIC_URL + "/images/appstore_badge_" + currentLanguage + ".svg"} className="" alt={currentLanguage} /></a>
                </div>
            </div>
        </div>
    )
}

export default HomePl
