import React from 'react'

interface Props {
    currentLanguage: string
}

const HomeEn = ({ currentLanguage } : Props) => {
    return (
        <div className='mainContainer'>
            <img src={process.env.PUBLIC_URL + "/images/splash_1024_500_" + currentLanguage + "_no_grad.png"} className="headerImage" alt={currentLanguage} />
            
            <div className='tile'>
                <div className='tileHeader'>So you want to change your weight</div>
                <ul>
                    <li>Whether you want to loose or put on some kgs it really seems to be simple task. But, if you tried it before (and maybe failed), you already know that there is more to it than it seems.</li>
                    <li>My goal was to loose weight. I tried many things. I failed many times. In the end I succeded to get my weight to certain level and I keep it there steady for a long time now.</li>
                    <li>What worked for me was really just making everything as simple as possible and stick to the plan. <b>Caloriator</b> was crutial element of this plan as it kept my perspective clear. It is a tool I created for myself on my way. I share it with you - maybe it will become your weight sentinel too.</li>
                </ul>
            </div>

            <div className='tile'>
                <div className='tileHeader'>Things that you absolutely need to succeed</div>
                <ul>
                    <li><div className='liHeader'>Control what you eat and how much you eat</div><div className='liContent'>To change your weight what you eat and how much you eat is number one factor. This is where some kind of control needs to be added. Of course One can say that "I can just eat less.." and that would be truth. But in real life, if meals are not controlled precisely, plans to change weight tend to fail. <b>Caloriator is one of tools that can help you with this exact point.</b></div></li>
                    <li><div className='liHeader'>Motivation and Patience</div><div className='liContent'>Loosing weight is not very fast process. It will take weeks, months even. Patience is a must. If you take a break on the way - you will fail</div></li>
                    <li><div className='liHeader'>Exercises</div><div className='liContent'>It does not have to be extreem. 30 minute walk here and there. Some home body workout, Push ups, bike, stepper... there are many options. Keep your body busy so it burns fat. If you sit and do not move for the whole day it will take eternity to loose anything</div></li>
                    <li><div className='liHeader'>Be honest with yourself</div><div className='liContent'>Caloriator is just logging tool. It will give you clear view of what to expect basing on your input. But that is if you log everything as is. No cheating, no skipping entries. If you are not honest with yourself then whats the point?</div></li>
                </ul>
            </div>

            <div className='tile'>
                <div className='tileHeader'>How to use Caloriator</div>
                <ul>
                    <li>
                        <div className='liHeader'>Step 1 - Estimate your daily calorie target</div>
                        <div className='liContent'>
                            <div className='liContentLine'>You can calculate it yourself using your own criteria or use many such calculators online.</div>
                            <div className='liContentLine'>In Caloriator you can press button with heart icon on homepage or click on "<b>How many calories</b>" button on homepage.</div>
                            <div className='liContentLine'>This will get you to Calorie setup calculator. </div>
                            <div className='liContentLine'>Input your gender, weight, height and choose one of activity options that suit you the best.</div>
                            <div className='liContentLine'>You will get results which will describe what is adviced daily number of calories to maintain, loose or gain weight. </div>
                            <div className='liContentLine'>Pick one of options. Which option you pick will influence how limitations will be displayed in your results.</div>
                            <div className='liContentLine'>I encourage you to pick blue option (maintaining weight) and simply try to execute your daily meals to be below this target. You will be able to change what you set here at any time by going into settings.</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesform.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesform.png"} className="" alt={currentLanguage} /></a>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesresults.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/howmanycaloriesresults.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                    <li>
                        <div className='liHeader'>Step 2 - Log what you eat every day</div>
                        <div className='liContent'>
                            <div className='liContentLine'>This is done by going into calculator and by adding products / items that were consumed on that day</div>
                            <div className='liContentLine'>Caloriator comes with predefined list of products but you will probably add your own</div>
                            <div className='liContentLine'>Since many times meals on specific days tend to be the same there is also "Copy list from another day" option which will copy what was logged on specific day to different date.</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/calculator_norm.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/calculator_norm.png"} className="" alt={currentLanguage} /></a>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/pickup.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/pickup.png"} className="" alt={currentLanguage} /></a>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/productlist.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/productlist.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                    <li>
                        <div className='liHeader'>Step 3 - Monitor your current week progress</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Homepage will always display state of current week.</div>
                            <div className='liContentLine'>It will give you <b>weekly forecast</b> and <b>weekly caloric balance</b>. It is crutial to look at these things weekly since when you do it just daily it might happen that you take some day "off" and on this day you destroy effort by breaking the plan.</div>
                            <div className='liContentLine'>Weekly balance, if input data is clean and honest, will not let you cheat yourself.</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/home.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/home.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                    <li>
                        <div className='liHeader'>Step 4 - Monitor past weeks</div>
                        <div className='liContent'>
                            <div className='liContentLine'>This is pure for motivation. Check your past weeks to see that you are consistent.</div>
                            <div className='liContentLine'>In time your log will grow. Week by week. </div>
                            <div className='liContentLine'>In my case it turned out that sticking to plan that kept me -0.5 kg weekly was the most I could do to feel comfortable.</div>
                        </div>
                        <div className='liImages'>
                            <a href={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/logwithbar.png"} target="_blank"><img src={process.env.PUBLIC_URL + "/images/screens/" + currentLanguage + "/logwithbar.png"} className="" alt={currentLanguage} /></a>
                        </div>
                    </li>
                </ul>
            </div>  
            <div className='tile'>
                <div className='tileHeader'>How to measure results</div>
                <ul>
                    <li>
                        <div className='liHeader'>I would suggest to measure results once a week and, because we are looking at rather small results, precision matters so it is really good to apply the following rules</div>
                        <div className='liContent'>
                            <div className='liContentLine'>Use the same scales.</div>
                            <div className='liContentLine'>Take measurements in same place.</div>
                            <div className='liContentLine'>Take measurements at the same time of day. </div>
                            <div className='liContentLine'>It is very important to consider that what you eat on days preceding these measurements is similar - the point is not to eat  day or two before measurements meals that are high carb or high salt which will result in body keeping a lot of water. </div>
                        </div>
                    </li>
                </ul>
            </div>  
            <div className='tile'>
                <div className='tileHeader'>How to import products to Caloriator from csv file</div>
                <ul>
                    <li>
                        <div className='liContent'>
                            <div className='liContentLine'>You can import products to Caloriator from csv file.</div>
                            <div className='liContentLine'>Download csv file template from this location: <a href="https://caloriator.com/caloriator_import_products_example_en.csv">caloriator_import_products_example_en.csv</a>.</div>
                            <div className='liContentLine'>Fill it with your items.</div>
                            <div className='liContentLine'>Send file to your device.</div>
                            <div className='liContentLine'>In application, on Products list page, use list settings icon to open popup where you can import this file.</div>
                        </div>
                    </li>
                </ul>
            </div>  
            <div className='tileHeader'>Good luck on your caloric journey</div>
            <div className='tile' style={{textAlign: 'center'}}>
                <a href="mailto:funandgoapps@gmail.com">funandgoapps@gmail.com</a>
            </div> 
            <div className='tileHeader'>
                <div className='liImages'>   
                    <a href='https://play.google.com/store/apps/details?id=com.funandgoapps.caloriator' target="_blank"><img src={process.env.PUBLIC_URL + "/images/google-play-badge_" + currentLanguage + ".png"} className="" alt={currentLanguage} /></a>
                </div>
                <div className='liImages'>   
                    <a href='https://apps.apple.com/us/app/caloriator-calories-calculator/id6476209779' target="_blank"><img src={process.env.PUBLIC_URL + "/images/appstore_badge_" + currentLanguage + ".svg"} className="" alt={currentLanguage} /></a>
                </div>
            </div>
        </div>
    )
}

export default HomeEn
